// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-text h2 {
  color: #001f3f !important; /* Adjust text color */
  font-size: 2.5rem; /* Adjust text size */
  font-weight: 700 !important;
  text-align: center;
}

.centered-text p {
  font-family: Plus Jakarta Sans;
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: center;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/guest-view/ResetPassword.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,sBAAsB;EACjD,iBAAiB,EAAE,qBAAqB;EACxC,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".centered-text h2 {\n  color: #001f3f !important; /* Adjust text color */\n  font-size: 2.5rem; /* Adjust text size */\n  font-weight: 700 !important;\n  text-align: center;\n}\n\n.centered-text p {\n  font-family: Plus Jakarta Sans;\n  color: #2f2f2f;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 20.16px;\n  text-align: center;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
