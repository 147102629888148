// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-card-container {
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: 0.1rem solid #b5b5b5;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.booking-card-container .booking-card-heading {
  color: #1c1c1c;
  font-size: 2.2rem;
}
.booking-card-container .booking-card-heading .mentor-name {
  background: linear-gradient(288deg, #001f3f -20.42%, #009dd1 62.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.booking-card-container .booking-tag-content {
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  width: max-content;
}
.booking-card-container .booking-tag-content.completed {
  color: #1bbb62;
  background: #daf9e8;
}
.booking-card-container .booking-tag-content.in-progress {
  color: #f9912e;
  background: #faf4ee;
}
.booking-card-container .booking-time-slot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-card-container .booking-time-slot-container .slot-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.booking-card-container .booking-time-slot-container .slot-content .slot-info {
  color: #1c1c1c;
  font-size: 1.8rem;
}
.booking-card-container .ant-btn.reschedule-button {
  height: 3.9rem;
  width: 13rem;
  border-color: #a8aaae;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/bookingCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EAEA,aAAA;EACA,sBAAA;EACA,WAAA;AAAF;AAEE;EACE,cAAA;EACA,iBAAA;AAAJ;AAEI;EACE,oEAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;AAAN;AAIE;EACE,oBAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AAFJ;AAII;EACE,cAAA;EACA,mBAAA;AAFN;AAKI;EACE,cAAA;EACA,mBAAA;AAHN;AAOE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALJ;AAOI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AALN;AAOM;EACE,cAAA;EACA,iBAAA;AALR;AAUE;EACE,cAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;AARJ","sourcesContent":[".booking-card-container {\n  padding: 2.4rem;\n  border-radius: 1.6rem;\n  border: 0.1rem solid #b5b5b5;\n  background: #fff;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1.6rem;\n\n  .booking-card-heading {\n    color: #1c1c1c;\n    font-size: 2.2rem;\n\n    .mentor-name {\n      background: linear-gradient(288deg, #001f3f -20.42%, #009dd1 62.87%);\n      background-clip: text;\n      -webkit-background-clip: text;\n      -webkit-text-fill-color: transparent;\n    }\n  }\n\n  .booking-tag-content {\n    padding: 0.5rem 1rem;\n    border-radius: 0.4rem;\n    font-size: 1.4rem;\n    width: max-content;\n\n    &.completed {\n      color: #1bbb62;\n      background: #daf9e8;\n    }\n\n    &.in-progress {\n      color: #f9912e;\n      background: #faf4ee;\n    }\n  }\n\n  .booking-time-slot-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .slot-content {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      gap: 0.5rem;\n\n      .slot-info {\n        color: #1c1c1c;\n        font-size: 1.8rem;\n      }\n    }\n  }\n\n  .ant-btn.reschedule-button {\n    height: 3.9rem;\n    width: 13rem;\n    border-color: #a8aaae;\n    background-color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
