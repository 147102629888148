// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.main-layout-container::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 1rem;
  background: #ccc;
}
.main-layout-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 1px solid transparent;
  border-radius: 1rem;
}
.main-layout-container {
  scrollbar-color: #ccc transparent;
  scrollbar-width: thin;
}`, "",{"version":3,"sources":["webpack://./src/pages/myProfile/styles.scss"],"names":[],"mappings":"AACE;EACE,QAAA;EACA,SAAA;AAAJ;AAGE;EAEE,gBAAA;EAEA,mBAAA;EACA,gBAAA;AADJ;AAIE;EACE,sBAAA;EACA,8BAAA;EAEA,mBAAA;AAFJ;AAME;EACE,iCAAA;EACA,qBAAA;AAJJ","sourcesContent":[".main-layout-container {\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  &::-webkit-scrollbar-track {\n    -webkit-box-shadow: none;\n    box-shadow: none;\n    -webkit-border-radius: 1rem;\n    border-radius: 1rem;\n    background: #ccc;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #ccc;\n    outline: 1px solid transparent;\n    -webkit-border-radius: 1rem;\n    border-radius: 1rem;\n  }\n\n  // Moz Support\n  & {\n    scrollbar-color: #ccc transparent;\n    scrollbar-width: thin;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
