// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor-location {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.mentor-location .location {
  color: #2f2c39;
  font-size: 1.6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/locationWithIcon/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;AACF;AACE;EACE,cAAA;EACA,iBAAA;AACJ","sourcesContent":[".mentor-location {\n  display: flex;\n  align-items: center;\n  gap: 0.5em;\n\n  .location {\n    color: #2f2c39;\n    font-size: 1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
