// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors-main-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.mentors-main-container .mentors-fields {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  grid-template-rows: 4.8rem;
  gap: 1.6rem;
}
.mentors-main-container .mentors-wrapper {
  padding: 2.4rem;
  border-radius: 1.2rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(165, 163, 174, 0.3019607843);
  background-color: #fff;
}
.mentors-main-container .mentors-wrapper .mentors-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem 2.4rem;
}
@media (min-width: 1260px) {
  .mentors-main-container .mentors-wrapper .mentors-cards-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1750px) {
  .mentors-main-container .mentors-wrapper .mentors-cards-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/mentors/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,yCAAA;EACA,0BAAA;EACA,WAAA;AACJ;AAEE;EACE,eAAA;EACA,qBAAA;EACA,+DAAA;EACA,sBAAA;AAAJ;AAEI;EACE,aAAA;EACA,0BAAA;EACA,kBAAA;AAAN;AAEM;EALF;IAMI,8BAAA;EACN;AACF;AACM;EATF;IAUI,kCAAA;EAEN;AACF","sourcesContent":[".mentors-main-container {\n  display: flex;\n  flex-direction: column;\n  gap: 1.6rem;\n\n  .mentors-fields {\n    display: grid;\n    grid-template-columns: 2fr repeat(4, 1fr);\n    grid-template-rows: 4.8rem;\n    gap: 1.6rem;\n  }\n\n  .mentors-wrapper {\n    padding: 2.4rem;\n    border-radius: 1.2rem;\n    box-shadow: 0 0.2rem 0.4rem 0 #a5a3ae4d;\n    background-color: #fff;\n\n    .mentors-cards-container {\n      display: grid;\n      grid-template-columns: 1fr;\n      gap: 1.6rem 2.4rem;\n\n      @media (min-width: 1260px) {\n        grid-template-columns: 1fr 1fr;\n      }\n\n      @media (min-width: 1750px) {\n        grid-template-columns: 1fr 1fr 1fr;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
