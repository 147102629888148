// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tabs-wrapper.ant-tabs .ant-tabs-nav::before {
  content: none;
}
.custom-tabs-wrapper.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 1rem 2rem;
  margin: 0;
}
.custom-tabs-wrapper.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 1.5rem;
  color: #52595c;
}
.custom-tabs-wrapper.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: -webkit-linear-gradient(287.99deg, #001f3f -20.42%, #009dd1 62.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}
.custom-tabs-wrapper.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
  height: 0.3rem;
  background: #009dd1;
}`, "",{"version":3,"sources":["webpack://./src/components/customTabs/styles.scss"],"names":[],"mappings":"AAGM;EACE,aAAA;AAFR;AAOU;EACE,kBAAA;EACA,SAAA;AALZ;AAOY;EACE,iBAAA;EACA,cAAA;AALd;AAUc;EACE,+EAAA;EAKA,6BAAA;EACA,oCAAA;EACA,iBAAA;AAZhB;AAiBU;EACE,cAAA;EACA,mBAAA;AAfZ","sourcesContent":[".custom-tabs-wrapper {\n  &.ant-tabs {\n    .ant-tabs-nav {\n      &::before {\n        content: none;\n      }\n\n      .ant-tabs-nav-wrap {\n        .ant-tabs-nav-list {\n          .ant-tabs-tab {\n            padding: 1rem 2rem;\n            margin: 0;\n\n            .ant-tabs-tab-btn {\n              font-size: 1.5rem;\n              color: #52595c;\n              //   font-weight: 400;\n            }\n\n            &.ant-tabs-tab-active {\n              .ant-tabs-tab-btn {\n                background: -webkit-linear-gradient(\n                  287.99deg,\n                  #001f3f -20.42%,\n                  #009dd1 62.87%\n                );\n                -webkit-background-clip: text;\n                -webkit-text-fill-color: transparent;\n                text-shadow: none;\n              }\n            }\n          }\n\n          .ant-tabs-ink-bar {\n            height: 0.3rem;\n            background: #009dd1;\n          }\n        }\n      }\n    }\n\n    .ant-tabs-content-holder {\n      .ant-tabs-content {\n        .ant-tabs-tabpane {\n          &.ant-tabs-tabpane-active {\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
