// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tag {
  border-radius: 0.8rem;
  background-color: #EFF3F4 !important;
  padding: 0rem 0.8rem;
  width: 100%;
  max-width: 200px !important;
  font-size: 1.6rem;
  font-weight: 600;
  color: #2F2C39 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/tag/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,oCAAA;EACA,oBAAA;EACA,WAAA;EACA,2BAAA;EAEA,iBAAA;EACA,gBAAA;EACA,yBAAA;AAAF","sourcesContent":[".custom-tag {\n  border-radius: 0.8rem;\n  background-color: #EFF3F4 !important;\n  padding: 0rem 0.8rem;\n  width: 100%;\n  max-width: 200px !important;\n\n  font-size: 1.6rem;\n  font-weight: 600;\n  color: #2F2C39 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
