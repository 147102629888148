// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.settings-form-wrapper .form-highlighted-heading {
  font-weight: 400;
  color: #1c1c1c;
}
.settings-form-wrapper .form-field-group-heading {
  color: #2f2c39;
  font-weight: 600;
}
.settings-form-wrapper .form-fields-group {
  display: flex;
  gap: 1.6rem;
  padding: 0 1.6rem;
}
@media (max-width: 767px) {
  .settings-form-wrapper .form-fields-group {
    flex-direction: column;
  }
}
.settings-form-wrapper .form-fields-group .form-field-with-label-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.settings-form-wrapper .form-fields-group .form-field-with-label-group.half-size-field {
  width: 50%;
  padding-right: 0.8rem;
}
@media (max-width: 767px) {
  .settings-form-wrapper .form-fields-group .form-field-with-label-group.half-size-field {
    width: 100%;
    padding-right: 0;
  }
}
.settings-form-wrapper .form-fields-group .form-field-with-label-group .field-label {
  color: #2f2c39;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.settings-form-wrapper .removal-action-group {
  display: flex;
  gap: 1.6rem;
  padding: 0.2rem 0;
}
@media (min-width: 768px) {
  .settings-form-wrapper .removal-action-group {
    padding: 0.2rem 1.2rem;
  }
}
.settings-form-wrapper .removal-action-group .ant-btn.deactivate {
  background-color: #52595c;
  color: #fff;
}
.settings-form-wrapper .removal-action-group .ant-btn.delete {
  background-color: #e8381a;
  color: #fff;
}
.settings-form-wrapper .ant-btn.save {
  align-self: flex-end;
  margin: 0.8rem 1.6rem 0.8rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;EACA,cAAA;AACJ;AAEE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,WAAA;EAEA,iBAAA;AAFJ;AAII;EANF;IAOI,sBAAA;EADJ;AACF;AAGI;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;AADN;AAGM;EACE,UAAA;EACA,qBAAA;AADR;AAGQ;EAJF;IAKI,WAAA;IACA,gBAAA;EAAR;AACF;AAGM;EACE,cAAA;EACA,iBAAA;EACA,qBAAA;AADR;AAME;EACE,aAAA;EACA,WAAA;EAEA,iBAAA;AALJ;AAOI;EANF;IAOI,sBAAA;EAJJ;AACF;AAOM;EACE,yBAAA;EACA,WAAA;AALR;AAQM;EACE,yBAAA;EACA,WAAA;AANR;AAWE;EACE,oBAAA;EACA,8BAAA;AATJ","sourcesContent":[".settings-form-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 1.6rem;\n\n  .form-highlighted-heading {\n    font-weight: 400;\n    color: #1c1c1c;\n  }\n\n  .form-field-group-heading {\n    color: #2f2c39;\n    font-weight: 600;\n  }\n\n  .form-fields-group {\n    display: flex;\n    gap: 1.6rem;\n\n    padding: 0 1.6rem;\n\n    @media (max-width: 767px) {\n      flex-direction: column;\n    }\n\n    .form-field-with-label-group {\n      display: flex;\n      flex-direction: column;\n      gap: 0.5rem;\n      width: 100%;\n\n      &.half-size-field {\n        width: 50%;\n        padding-right: 0.8rem;\n\n        @media (max-width: 767px) {\n          width: 100%;\n          padding-right: 0;\n        }\n      }\n\n      .field-label {\n        color: #2f2c39;\n        font-size: 1.3rem;\n        margin-bottom: 0.5rem;\n      }\n    }\n  }\n\n  .removal-action-group {\n    display: flex;\n    gap: 1.6rem;\n\n    padding: 0.2rem 0;\n\n    @media (min-width: 768px) {\n      padding: 0.2rem 1.2rem;\n    }\n\n    .ant-btn {\n      &.deactivate {\n        background-color: #52595c;\n        color: #fff;\n      }\n\n      &.delete {\n        background-color: #e8381a;\n        color: #fff;\n      }\n    }\n  }\n\n  .ant-btn.save {\n    align-self: flex-end;\n    margin: 0.8rem 1.6rem 0.8rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
