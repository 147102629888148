// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-pagination.custom-pagination {
  margin-top: 1.2rem;
  gap: 0.6rem;
  justify-content: center;
}
.ant-pagination.custom-pagination .ant-pagination-prev,
.ant-pagination.custom-pagination .ant-pagination-next,
.ant-pagination.custom-pagination .ant-pagination-item {
  margin: 0;
  border-radius: 0.4rem;
  background: #eff3f4;
}
.ant-pagination.custom-pagination .ant-pagination-prev > a,
.ant-pagination.custom-pagination .ant-pagination-next > a,
.ant-pagination.custom-pagination .ant-pagination-item > a {
  color: #4b465c;
  font-size: 1.3rem;
  font-weight: 600;
}
.ant-pagination.custom-pagination .ant-pagination-prev.ant-pagination-item-active,
.ant-pagination.custom-pagination .ant-pagination-next.ant-pagination-item-active,
.ant-pagination.custom-pagination .ant-pagination-item.ant-pagination-item-active {
  background: #009dd1;
  border-color: #009dd1;
}
.ant-pagination.custom-pagination .ant-pagination-prev.ant-pagination-item-active > a,
.ant-pagination.custom-pagination .ant-pagination-next.ant-pagination-item-active > a,
.ant-pagination.custom-pagination .ant-pagination-item.ant-pagination-item-active > a {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/customPagination/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,uBAAA;AACF;AACE;;;EAGE,SAAA;EACA,qBAAA;EACA,mBAAA;AACJ;AACI;;;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;AAGN;AAGI;;;EACE,mBAAA;EACA,qBAAA;AACN;AACM;;;EACE,WAAA;AAGR","sourcesContent":[".ant-pagination.custom-pagination {\n  margin-top: 1.2rem;\n  gap: 0.6rem;\n  justify-content: center;\n\n  .ant-pagination-prev,\n  .ant-pagination-next,\n  .ant-pagination-item {\n    margin: 0;\n    border-radius: 0.4rem;\n    background: #eff3f4;\n\n    > a {\n      color: #4b465c;\n      font-size: 1.3rem;\n      font-weight: 600;\n    }\n\n    &.ant-pagination-disabled {\n    }\n\n    &.ant-pagination-item-active {\n      background: #009dd1;\n      border-color: #009dd1;\n\n      > a {\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
