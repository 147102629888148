// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-header-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 1.6rem;
}
@media (max-width: 767px) {
  .profile-header-container {
    flex-direction: column;
  }
}
.profile-header-container .user-profile-image-wrapper {
  height: 20rem;
  margin-bottom: 1.6rem;
}
@media (min-width: 768px) {
  .profile-header-container .user-profile-image-wrapper {
    margin-right: 1.6rem;
    margin-bottom: 0;
  }
}
.profile-header-container .user-profile-image-wrapper .user-profile-image {
  height: 100%;
}
@media (max-width: 767px) {
  .profile-header-container .profile-user-details {
    display: flex;
    flex-direction: column;
  }
}
.profile-header-container .profile-user-details .edit-profile-button {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .profile-header-container .profile-user-details .edit-profile-button {
    order: 1;
  }
}
.profile-header-container .profile-user-details .profile-user-name {
  margin-bottom: 1.5rem;
}
.profile-header-container .profile-user-details .profile-email {
  margin-bottom: 1rem;
}
.profile-header-container .profile-user-details .profile-phone-number {
  margin-bottom: 0.5rem;
}
.profile-header-container .profile-user-details .profile-id {
  background-color: #e2f3f9;
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  color: #0077a6;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/profileHeader/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EAEA,gCAAA;EACA,sBAAA;AAAF;AAEE;EAPF;IAQI,sBAAA;EACF;AACF;AACE;EACE,aAAA;EACA,qBAAA;AACJ;AACI;EAJF;IAKI,oBAAA;IACA,gBAAA;EAEJ;AACF;AAAI;EACE,YAAA;AAEN;AAGI;EADF;IAEI,aAAA;IACA,sBAAA;EAAJ;AACF;AAEI;EACE,aAAA;EACA,oBAAA;EAEA,mBAAA;AADN;AAGM;EANF;IAOI,QAAA;EAAN;AACF;AAGI;EACE,qBAAA;AADN;AAII;EACE,mBAAA;AAFN;AAKI;EACE,qBAAA;AAHN;AAMI;EACE,yBAAA;EACA,kBAAA;EACA,oBAAA;EACA,qBAAA;EAEA,cAAA;EACA,gBAAA;AALN","sourcesContent":[".profile-header-container {\n  display: flex;\n  align-items: center;\n\n  border-bottom: 1px solid #b5b5b5;\n  padding-bottom: 1.6rem;\n\n  @media (max-width: 767px) {\n    flex-direction: column;\n  }\n\n  .user-profile-image-wrapper {\n    height: 20rem;\n    margin-bottom: 1.6rem;\n\n    @media (min-width: 768px) {\n      margin-right: 1.6rem;\n      margin-bottom: 0;\n    }\n\n    .user-profile-image {\n      height: 100%;\n    }\n  }\n\n  .profile-user-details {\n    @media (max-width: 767px) {\n      display: flex;\n      flex-direction: column;\n    }\n\n    .edit-profile-button {\n      display: flex;\n      justify-content: end;\n\n      margin-bottom: 1rem;\n\n      @media (max-width: 767px) {\n        order: 1;\n      }\n    }\n\n    .profile-user-name {\n      margin-bottom: 1.5rem;\n    }\n\n    .profile-email {\n      margin-bottom: 1rem;\n    }\n\n    .profile-phone-number {\n      margin-bottom: 0.5rem;\n    }\n\n    .profile-id {\n      background-color: #e2f3f9;\n      width: max-content;\n      padding: 0.5rem 1rem;\n      border-radius: 0.6rem;\n\n      color: #0077a6;\n      font-weight: 600;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
