// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-notifications-container {
  height: calc(100vh - 17rem);
}
.empty-notifications-container .no-notification-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-notifications-container .no-notification-container .no-notification-image {
  width: 100%;
}

.notifications-cards-wrapper {
  height: calc(100vh - 17rem);
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-bottom: 2rem;
}
.notifications-cards-wrapper .notification-container {
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: 1px solid #aeacb4;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.notifications-cards-wrapper .notification-container .notification-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.notifications-cards-wrapper .notification-container .notification-title .title {
  color: #2f2c39;
  font-size: 2.2rem;
}
.notifications-cards-wrapper .notification-container .notification-title .name {
  font-size: 2.2rem;
  background: -webkit-linear-gradient(287.99deg, #001f3f -20.42%, #009dd1 62.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notifications-cards-wrapper .notification-container .notification-content {
  color: #2f2c39;
}
.notifications-cards-wrapper .notification-container .notification-actions {
  height: 3.6rem;
}
.notifications-cards-wrapper .notification-container .time-span {
  color: #1c1c1c;
  font-size: 1.8rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/recruiterNotification/styles.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AACE;EACE,YAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAEI;EACE,WAAA;AAAN;;AAKA;EACE,2BAAA;EAEA,aAAA;EACA,sBAAA;EACA,WAAA;EAEA,oBAAA;AAJF;AAME;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,iDAAA;EACA,sBAAA;EAEA,aAAA;EACA,sBAAA;EACA,WAAA;AALJ;AASI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAPN;AASM;EACE,cAAA;EACA,iBAAA;AAPR;AAUM;EACE,iBAAA;EACA,+EAAA;EAKA,6BAAA;EACA,oCAAA;AAZR;AAgBI;EACE,cAAA;AAdN;AAiBI;EACE,cAAA;AAfN;AAkBI;EACE,cAAA;EACA,iBAAA;AAhBN","sourcesContent":[".empty-notifications-container {\n  height: calc(100vh - 17rem);\n\n  .no-notification-container {\n    height: 100%;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .no-notification-image {\n      width: 100%;\n    }\n  }\n}\n\n.notifications-cards-wrapper {\n  height: calc(100vh - 17rem);\n\n  display: flex;\n  flex-direction: column;\n  gap: 1.2rem;\n\n  padding-bottom: 2rem;\n\n  .notification-container {\n    padding: 2.4rem;\n    border-radius: 1.6rem;\n    border: 1px solid #aeacb4;\n    box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);\n    background-color: #fff;\n\n    display: flex;\n    flex-direction: column;\n    gap: 1.2rem;\n\n   \n\n    .notification-title {\n      display: flex;\n      align-items: center;\n      gap: 1rem;\n\n      .title {\n        color: #2f2c39;\n        font-size: 2.2rem;\n      }\n\n      .name {\n        font-size: 2.2rem;\n        background: -webkit-linear-gradient(\n          287.99deg,\n          #001f3f -20.42%,\n          #009dd1 62.87%\n        );\n        -webkit-background-clip: text;\n        -webkit-text-fill-color: transparent;\n      }\n    }\n\n    .notification-content {\n      color: #2f2c39;\n    }\n\n    .notification-actions {\n      height: 3.6rem;\n    }\n\n    .time-span {\n      color: #1c1c1c;\n      font-size: 1.8rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
