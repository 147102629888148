// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-upload-wrapper.custom-document-uploader-container {
  width: 100%;
}
.ant-upload-wrapper.custom-document-uploader-container .ant-upload.ant-upload-select {
  height: 3.8rem;
  width: 100%;
}
.ant-upload-wrapper.custom-document-uploader-container .ant-upload.ant-upload-select > .ant-upload .custom-uploader-button {
  height: 3.8rem;
  width: 100%;
  background-color: #697e85;
  border: 0.1rem solid #697e85;
}
.ant-upload-wrapper.custom-document-uploader-container .ant-upload.ant-upload-select > .ant-upload .custom-uploader-button .uploader-button-title {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/documentUploader/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEI;EACE,cAAA;EACA,WAAA;AAAN;AAGQ;EACE,cAAA;EACA,WAAA;EACA,yBAAA;EACA,4BAAA;AADV;AAQU;EACE,iBAAA;EACA,gBAAA;EACA,WAAA;AANZ","sourcesContent":[".ant-upload-wrapper {\n  &.custom-document-uploader-container {\n    width: 100%;\n\n    .ant-upload.ant-upload-select {\n      height: 3.8rem;\n      width: 100%;\n\n      > .ant-upload {\n        .custom-uploader-button {\n          height: 3.8rem;\n          width: 100%;\n          background-color: #697e85;\n          border: 0.1rem solid #697e85;\n\n          .ant-btn-icon {\n            > svg {\n            }\n          }\n\n          .uploader-button-title {\n            font-size: 1.6rem;\n            font-weight: 500;\n            color: #fff;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
