// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.earnings-main-layout-container {
  padding: 3.6rem 0;
  border-radius: 0.6rem;
  background-color: #ffffff;
  overflow-y: auto;
}
.earnings-main-layout-container .download-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
}
.earnings-main-layout-container .download-buttons-wrapper .ant-btn.custom-button.download-button {
  width: max-content;
  height: 4rem;
  border-radius: 0.8rem;
  background: #e9f0f3;
  padding: 0 2rem;
}
.earnings-main-layout-container .download-buttons-wrapper .ant-btn.custom-button.download-button .button-title {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.earnings-main-layout-container .download-buttons-wrapper .ant-btn.custom-button.download-button .button-title .title {
  color: #2f2c39;
  font-weight: 500;
}
.earnings-main-layout-container .earning-detail-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
}
.earnings-main-layout-container .earning-detail-container .grand-total {
  color: #1c1c1c;
}
.earnings-main-layout-container .earning-detail-container .earning-detail {
  color: #2f2c39;
  font-weight: 400;
}
.earnings-main-layout-container .earning-detail-container .earning-detail .amount {
  color: #2f2c39;
  font-weight: 600;
}
.earnings-main-layout-container .earnings-search-fields-wrapper {
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem;
}
.earnings-main-layout-container .earnings-search-fields-wrapper .ant-input-affix-wrapper.earnings-search-field {
  height: 4.8rem;
  width: 41rem;
}
.earnings-main-layout-container .earnings-search-fields-wrapper .ant-picker.earnings-select-field {
  height: 4.8rem;
  width: 22rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/earnings/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AAEM;EACE,kBAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;AAAR;AAEQ;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAAV;AAEU;EACE,cAAA;EACA,gBAAA;AAAZ;AAOE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AALJ;AAOI;EACE,cAAA;AALN;AAQI;EACE,cAAA;EACA,gBAAA;AANN;AAQM;EACE,cAAA;EACA,gBAAA;AANR;AAWE;EACE,aAAA;EACA,WAAA;EACA,eAAA;AATJ;AAWI;EACE,cAAA;EACA,YAAA;AATN;AAYI;EACE,cAAA;EACA,YAAA;AAVN","sourcesContent":[".earnings-main-layout-container {\n  padding: 3.6rem 0;\n  border-radius: 0.6rem;\n  background-color: #ffffff;\n  overflow-y: auto;\n\n  .download-buttons-wrapper {\n    display: flex;\n    justify-content: flex-end;\n    gap: 1.6rem;\n    padding: 0 1.6rem;\n    margin-bottom: 1.6rem;\n\n    .ant-btn.custom-button {\n      &.download-button {\n        width: max-content;\n        height: 4rem;\n        border-radius: 0.8rem;\n        background: #e9f0f3;\n        padding: 0 2rem;\n\n        .button-title {\n          display: flex;\n          align-items: center;\n          gap: 0.4rem;\n\n          .title {\n            color: #2f2c39;\n            font-weight: 500;\n          }\n        }\n      }\n    }\n  }\n\n  .earning-detail-container {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    padding: 1.6rem;\n\n    .grand-total {\n      color: #1c1c1c;\n    }\n\n    .earning-detail {\n      color: #2f2c39;\n      font-weight: 400;\n\n      .amount {\n        color: #2f2c39;\n        font-weight: 600;\n      }\n    }\n  }\n\n  .earnings-search-fields-wrapper {\n    display: flex;\n    gap: 1.6rem;\n    padding: 1.6rem;\n\n    .ant-input-affix-wrapper.earnings-search-field {\n      height: 4.8rem;\n      width: 41rem;\n    }\n\n    .ant-picker.earnings-select-field {\n      height: 4.8rem;\n      width: 22rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
