// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
}
.download-buttons-wrapper .ant-btn.custom-button.download-button {
  width: max-content;
  height: 4rem;
  border-radius: 0.8rem;
  background: #e9f0f3;
  padding: 0 2rem;
}
.download-buttons-wrapper .ant-btn.custom-button.download-button .button-title {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.download-buttons-wrapper .ant-btn.custom-button.download-button .button-title .title {
  color: #2f2c39;
  font-weight: 500;
}

.custom-table .ant-table-thead > tr > th {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  color: #393740 !important;
  font-weight: 400;
}

.custom-table .ant-table {
  background-color: #ffffff;
  margin-top: 30px;
}

.custom-table .ant-table-container {
  background-color: #ffffff;
}

.custom-table .ant-table-tbody > tr > td {
  color: #2F2C39;
}`, "",{"version":3,"sources":["webpack://./src/components/ADMIN/admin-employer-styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AAEM;EACE,kBAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;AAAR;AAEQ;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAAV;AAEU;EACE,cAAA;EACA,gBAAA;AAAZ;;AAQE;EACE,yBAAA;EACA,gCAAA;EACA,yBAAA;EACA,gBAAA;AALJ;;AAQE;EACE,yBAAA;EACA,gBAAA;AALJ;;AAQE;EACE,yBAAA;AALJ;;AAQE;EACE,cAAA;AALJ","sourcesContent":[".download-buttons-wrapper {\n    display: flex;\n    justify-content: flex-end;\n    gap: 1.6rem;\n    padding: 0 1.6rem;\n    margin-bottom: 1.6rem;\n\n    .ant-btn.custom-button {\n      &.download-button {\n        width: max-content;\n        height: 4rem;\n        border-radius: 0.8rem;\n        background: #e9f0f3;\n        padding: 0 2rem;\n\n        .button-title {\n          display: flex;\n          align-items: center;\n          gap: 0.4rem;\n\n          .title {\n            color: #2f2c39;\n            font-weight: 500;\n          }\n        }\n      }\n    }\n  }\n\n\n  .custom-table .ant-table-thead > tr > th {\n    background-color: #ffffff;\n    border-bottom: 1px solid #f0f0f0;\n    color: #393740 !important;\n    font-weight: 400;\n  }\n  \n  .custom-table .ant-table {\n    background-color: #ffffff;\n    margin-top: 30px;\n  }\n  \n  .custom-table .ant-table-container {\n    background-color: #ffffff;\n  }\n  \n  .custom-table .ant-table-tbody > tr > td {\n    color: #2F2C39;\n  }\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
