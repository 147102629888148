// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-and-links-wrapper {
  padding: 1.6rem 0;
  border-bottom: 1px solid #b5b5b5;
}
.document-and-links-wrapper .document-links-wrapper {
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.document-and-links-wrapper .document-links-wrapper .documents-list-container {
  display: flex;
  padding: 1.2rem 0rem;
}
@media (min-width: 768px) {
  .document-and-links-wrapper .document-links-wrapper .documents-list-container {
    padding: 1.2rem 1.6rem;
  }
}
.document-and-links-wrapper .document-links-wrapper .documents-list-container .documents-details-container {
  flex-grow: 1;
  display: flex;
}
.document-and-links-wrapper .document-links-wrapper .documents-list-container .documents-details-container .documents-details {
  margin-left: 1.2rem;
}
.document-and-links-wrapper .document-links-wrapper .documents-list-container .documents-details-container .documents-details .file-name {
  font-size: 1.6rem;
  font-weight: 500;
  color: #111112;
  margin-bottom: 1rem;
}
.document-and-links-wrapper .document-links-wrapper .documents-list-container .documents-details-container .documents-details .file-date {
  font-size: 1.4rem;
  color: #2f2c39;
  margin-bottom: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/documentAndLink/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gCAAA;AACF;AACE;EACE,gBAAA;EACA,qBAAA;EAEA,aAAA;EACA,sBAAA;EACA,aAAA;AAAJ;AAEI;EACE,aAAA;EACA,oBAAA;AAAN;AAEM;EAJF;IAKI,sBAAA;EACN;AACF;AACM;EACE,YAAA;EACA,aAAA;AACR;AACQ;EACE,mBAAA;AACV;AACU;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACZ;AAEU;EACE,iBAAA;EACA,cAAA;EACA,qBAAA;AAAZ","sourcesContent":[".document-and-links-wrapper {\n  padding: 1.6rem 0;\n  border-bottom: 1px solid #b5b5b5;\n\n  .document-links-wrapper {\n    margin-top: 1rem;\n    margin-bottom: 1.6rem;\n\n    display: flex;\n    flex-direction: column;\n    row-gap: 1rem;\n\n    .documents-list-container {\n      display: flex;\n      padding: 1.2rem 0rem;\n\n      @media (min-width: 768px) {\n        padding: 1.2rem 1.6rem;\n      }\n\n      .documents-details-container {\n        flex-grow: 1;\n        display: flex;\n\n        .documents-details {\n          margin-left: 1.2rem;\n\n          .file-name {\n            font-size: 1.6rem;\n            font-weight: 500;\n            color: #111112;\n            margin-bottom: 1rem;\n          }\n\n          .file-date {\n            font-size: 1.4rem;\n            color: #2f2c39;\n            margin-bottom: 0.8rem;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
