// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-card-wrapper {
  box-shadow: 0.2rem 0.2rem 1.6rem 0 #cfe6ee;
  border: 0.1rem solid transparent;
  background: linear-gradient(white, white) padding-box, linear-gradient(287.99deg, #2f2c39 -20.42%, #2f2c39 62.87%) border-box;
  padding: 1.2rem 1.6rem;
  border-radius: 1.6rem;
  cursor: pointer;
  transition: all 0.3s linear;
  position: relative;
}
.job-card-wrapper.active {
  transition: all 0.3s linear;
  background: linear-gradient(white, white) padding-box, linear-gradient(287.99deg, #001f3f -20.42%, #009dd1 62.87%) border-box;
}
.job-card-wrapper > svg {
  position: absolute;
  right: 1.6rem;
  top: 1.2rem;
}
.job-card-wrapper .job-company-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.job-card-wrapper .job-company-container .company-logo {
  height: 5.6rem;
  width: 5.5rem;
}
.job-card-wrapper .job-company-container .job-company-details .name,
.job-card-wrapper .job-company-container .job-company-details .location {
  font-size: 1.4rem;
  font-weight: 400;
  color: #2f2c39;
}
.job-card-wrapper .job-main-title {
  font-weight: 600;
  color: #2f2c39;
  margin: 0.8rem 0;
}
.job-card-wrapper .job-description-list {
  padding-left: 2rem;
  margin-top: 0.5rem;
}
.job-card-wrapper .job-description-list .list-item {
  font-size: 1.4rem;
  font-weight: 400;
}
.job-card-wrapper .activity-status {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #2f2c39;
}`, "",{"version":3,"sources":["webpack://./src/components/jobCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,gCAAA;EACA,6HAAA;EAEA,sBAAA;EACA,qBAAA;EACA,eAAA;EACA,2BAAA;EAEA,kBAAA;AADF;AAGE;EACE,2BAAA;EACA,6HAAA;AADJ;AAKE;EACE,kBAAA;EAEA,aAAA;EACA,WAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AALJ;AAOI;EACE,cAAA;EACA,aAAA;AALN;AASM;;EAEE,iBAAA;EACA,gBAAA;EACA,cAAA;AAPR;AAYE;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;AAVJ;AAaE;EACE,kBAAA;EACA,kBAAA;AAXJ;AAaI;EACE,iBAAA;EACA,gBAAA;AAXN;AAeE;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAbJ","sourcesContent":[".job-card-wrapper {\n  box-shadow: 0.2rem 0.2rem 1.6rem 0 #cfe6ee;\n  border: 0.1rem solid transparent;\n  background: linear-gradient(white, white) padding-box,\n    linear-gradient(287.99deg, #2f2c39 -20.42%, #2f2c39 62.87%) border-box;\n  padding: 1.2rem 1.6rem;\n  border-radius: 1.6rem;\n  cursor: pointer;\n  transition: all 0.3s linear;\n\n  position: relative;\n\n  &.active {\n    transition: all 0.3s linear;\n    background: linear-gradient(white, white) padding-box,\n      linear-gradient(287.99deg, #001f3f -20.42%, #009dd1 62.87%) border-box;\n  }\n\n  > svg {\n    position: absolute;\n\n    right: 1.6rem;\n    top: 1.2rem;\n  }\n\n  .job-company-container {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n\n    .company-logo {\n      height: 5.6rem;\n      width: 5.5rem;\n    }\n\n    .job-company-details {\n      .name,\n      .location {\n        font-size: 1.4rem;\n        font-weight: 400;\n        color: #2f2c39;\n      }\n    }\n  }\n\n  .job-main-title {\n    font-weight: 600;\n    color: #2f2c39;\n    margin: 0.8rem 0;\n  }\n\n  .job-description-list {\n    padding-left: 2rem;\n    margin-top: 0.5rem;\n\n    .list-item {\n      font-size: 1.4rem;\n      font-weight: 400;\n    }\n  }\n\n  .activity-status {\n    margin-top: 1rem;\n    font-size: 1.2rem;\n    font-weight: 400;\n    color: #2f2c39;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
