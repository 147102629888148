// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-collapse {
  background-color: transparent;
}
.ant-collapse .ant-collapse-item {
  border-bottom: none;
}
.ant-collapse .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 1rem;
}
.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  background-color: #e9f0f3;
}
.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0.8rem 1.5rem;
  align-items: center;
  border-radius: 1rem;
}
.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text .collapse-header-wrapper {
  display: flex;
}
.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text .collapse-header-wrapper .collapse-heading {
  font-size: 1.6rem;
  font-weight: normal;
  margin-left: 1rem;
}
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .collpase-items-list {
  padding-left: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .collpase-items-list .collpase-item {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .collpase-items-list .collpase-item.active {
  font-weight: 600;
  color: #1c1c1c;
}`, "",{"version":3,"sources":["webpack://./src/components/customCollapse/styles.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEM;EACE,mBAAA;AAAR;AAKM;EACE,yBAAA;AAHR;AAOI;EACE,sBAAA;EACA,mBAAA;EACA,mBAAA;AALN;AAeQ;EACE,aAAA;AAbV;AAkBU;EACE,iBAAA;EACA,mBAAA;EACA,iBAAA;AAhBZ;AAuBM;EACE,UAAA;AArBR;AAuBQ;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;AArBV;AAuBU;EACE,iBAAA;EACA,qBAAA;AArBZ;AAuBY;EACE,gBAAA;EACA,cAAA;AArBd","sourcesContent":[".ant-collapse {\n  background-color: transparent;\n\n  .ant-collapse-item {\n    border-bottom: none;\n\n    &:last-child {\n      .ant-collapse-header {\n        border-radius: 1rem;\n      }\n    }\n\n    &.ant-collapse-item-active {\n      .ant-collapse-header {\n        background-color: #e9f0f3;\n      }\n    }\n\n    .ant-collapse-header {\n      padding: 0.8rem 1.5rem;\n      align-items: center;\n      border-radius: 1rem;\n\n      .ant-collapse-expand-icon {\n        .anticon {\n          > svg {\n          }\n        }\n      }\n\n      .ant-collapse-header-text {\n        .collapse-header-wrapper {\n          display: flex;\n\n          > svg {\n          }\n\n          .collapse-heading {\n            font-size: 1.6rem;\n            font-weight: normal;\n            margin-left: 1rem;\n          }\n        }\n      }\n    }\n\n    .ant-collapse-content {\n      .ant-collapse-content-box {\n        padding: 0;\n\n        .collpase-items-list {\n          padding-left: 5rem;\n          padding-top: 1rem;\n          padding-bottom: 1rem;\n\n          .collpase-item {\n            font-size: 1.5rem;\n            margin-bottom: 0.5rem;\n\n            &.active {\n              font-weight: 600;\n              color: #1c1c1c;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
