// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-container {
  display: flex;
  align-items: center;
}
.rating-container .rating {
  color: #2f2c39;
  font-weight: 500;
}
.rating-container > svg {
  margin-left: 0.5rem;
}
.rating-container .reviews {
  margin-left: 0.5rem;
  color: #eecb16;
}`, "",{"version":3,"sources":["webpack://./src/components/rating/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,cAAA;AADJ","sourcesContent":[".rating-container {\n  display: flex;\n  align-items: center;\n\n  .rating {\n    color: #2f2c39;\n    font-weight: 500;\n  }\n\n  > svg {\n    margin-left: 0.5rem;\n  }\n\n  .reviews {\n    margin-left: 0.5rem;\n    color: #eecb16;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
