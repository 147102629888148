// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-card-container {
  padding: 2.4rem;
  border: 0.1rem solid #dddce2;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.review-card-container .review-card-header {
  display: flex;
  gap: 1.6rem;
}
.review-card-container .review-card-header .review-card-icon {
  height: 9rem;
  width: 9rem;
}
.review-card-container .review-card-header .review-card-icon .review-card-user-icon {
  height: 100%;
  width: 100%;
}
.review-card-container .review-card-header .review-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/components/reviewCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,4BAAA;EACA,qBAAA;EAEA,aAAA;EACA,sBAAA;EACA,WAAA;AAAF;AAEE;EACE,aAAA;EACA,WAAA;AAAJ;AAEI;EACE,YAAA;EACA,WAAA;AAAN;AAEM;EACE,YAAA;EACA,WAAA;AAAR;AAII;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;AAFN","sourcesContent":[".review-card-container {\n  padding: 2.4rem;\n  border: 0.1rem solid #dddce2;\n  border-radius: 1.6rem;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1.6rem;\n\n  .review-card-header {\n    display: flex;\n    gap: 1.6rem;\n\n    .review-card-icon {\n      height: 9rem;\n      width: 9rem;\n\n      .review-card-user-icon {\n        height: 100%;\n        width: 100%;\n      }\n    }\n\n    .review-card-details {\n      display: flex;\n      flex-direction: column;\n      justify-content: space-evenly;\n\n      .name {\n      }\n    }\n  }\n\n  .review-card-content {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
