// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tag-admin {
  display: inline-block;
  max-width: 150px;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s;
  font-weight: 500;
}
.custom-tag-admin:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/ADMIN/components/styles.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iCAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;AAER","sourcesContent":[".custom-tag-admin {\n    display: inline-block;\n    max-width: 150px;\n    width: 100%;\n    text-align: center;\n    padding: 5px 10px;\n    border-radius: 3px;\n    color: white;\n    cursor: pointer;\n    margin-right: 5px;\n    transition: background-color 0.3s;\n    font-weight: 500;\n    &:hover {\n        opacity: 0.8; // Optional hover effect\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
