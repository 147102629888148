// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input.common-textarea-field {
  border-radius: 0.6rem;
  border: 0.1rem solid #dbdade;
  height: 8.4rem;
  resize: none;
  width: 100%;
}
.ant-input.common-textarea-field > .ant-input {
  padding: 0.7rem 1.4rem;
}
.ant-input.common-textarea-field > .ant-input::placeholder {
  color: #aeacb4;
}
.ant-input.common-input-field {
  width: 100%;
  height: 3.5rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #dbdade;
  padding: 0.7rem 1.4rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.ant-input.common-input-field::placeholder {
  color: #aeacb4;
}

.ant-picker.ant-picker-outlined.common-datepicker-field {
  width: 100%;
  height: 3.5rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #dbdade;
  padding: 0.7rem 1.4rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.ant-picker.ant-picker-outlined.common-datepicker-field .ant-picker-input > input {
  font-size: 1.4rem;
  font-weight: 400;
}
.ant-picker.ant-picker-outlined.common-datepicker-field .ant-picker-input > input::placeholder {
  color: #aeacb4;
}
.ant-picker.ant-picker-outlined.common-datepicker-field .ant-picker-input .ant-picker-suffix .anticon.anticon-calendar > svg {
  fill: #4b465c;
}`, "",{"version":3,"sources":["webpack://./src/components/commonInput/styles.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,4BAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AAAJ;AAEI;EACE,sBAAA;AAAN;AAEM;EACE,cAAA;AAAR;AAKE;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,4BAAA;EACA,sBAAA;EAEA,iBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,cAAA;AAJN;;AAWI;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,4BAAA;EACA,sBAAA;EAEA,iBAAA;EACA,gBAAA;AATN;AAYQ;EACE,iBAAA;EACA,gBAAA;AAVV;AAYU;EACE,cAAA;AAVZ;AAgBY;EACE,aAAA;AAdd","sourcesContent":[".ant-input {\n  &.common-textarea-field {\n    border-radius: 0.6rem;\n    border: 0.1rem solid #dbdade;\n    height: 8.4rem;\n    resize: none;\n    width: 100%;\n\n    > .ant-input {\n      padding: 0.7rem 1.4rem;\n\n      &::placeholder {\n        color: #aeacb4;\n      }\n    }\n  }\n\n  &.common-input-field {\n    width: 100%;\n    height: 3.5rem;\n    border-radius: 0.6rem;\n    border: 0.1rem solid #dbdade;\n    padding: 0.7rem 1.4rem;\n\n    font-size: 1.4rem;\n    font-weight: 400;\n\n    &::placeholder {\n      color: #aeacb4;\n    }\n  }\n}\n\n.ant-picker {\n  &.ant-picker-outlined {\n    &.common-datepicker-field {\n      width: 100%;\n      height: 3.5rem;\n      border-radius: 0.6rem;\n      border: 0.1rem solid #dbdade;\n      padding: 0.7rem 1.4rem;\n\n      font-size: 1.4rem;\n      font-weight: 400;\n\n      .ant-picker-input {\n        > input {\n          font-size: 1.4rem;\n          font-weight: 400;\n\n          &::placeholder {\n            color: #aeacb4;\n          }\n        }\n\n        .ant-picker-suffix {\n          .anticon.anticon-calendar {\n            > svg {\n              fill: #4b465c;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
