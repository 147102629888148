// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews-main-layout-container {
  padding: 2.4rem 3.2rem;
  border-radius: 0.6rem;
  background-color: #ffffff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
.reviews-main-layout-container .review-cards-list-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.4rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/reviews/styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,qBAAA;EACA,yBAAA;EACA,gBAAA;EAEA,aAAA;EACA,sBAAA;EACA,WAAA;AAAF;AAEE;EACE,aAAA;EACA,kCAAA;EACA,WAAA;AAAJ","sourcesContent":[".reviews-main-layout-container {\n  padding: 2.4rem 3.2rem;\n  border-radius: 0.6rem;\n  background-color: #ffffff;\n  overflow-y: auto;\n\n  display: flex;\n  flex-direction: column;\n  gap: 3.2rem;\n\n  .review-cards-list-wrapper {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    gap: 2.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
