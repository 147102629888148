// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-upload-wrapper.common-avatar-uploader .ant-upload.ant-upload-select {
  width: 14rem;
  height: 14rem;
  border: 0.1rem solid #dddce2;
  background-color: #e9f0f3;
}
.ant-upload-wrapper.common-avatar-uploader .ant-upload.ant-upload-select:hover {
  border: 0.1rem solid #dddce2;
}
.ant-upload-wrapper.common-avatar-uploader .ant-upload.ant-upload-select > .ant-upload .common-uploader-button {
  cursor: pointer;
  border: 0;
  background: none;
}
.ant-upload-wrapper.common-avatar-uploader .ant-upload.ant-upload-select > .ant-upload .common-uploader-button .upload-button-label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2f2c39;
}`, "",{"version":3,"sources":["webpack://./src/components/photoUpload/styles.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;EACA,aAAA;EACA,4BAAA;EACA,yBAAA;AADN;AAGM;EACE,4BAAA;AADR;AAKQ;EACE,eAAA;EACA,SAAA;EACA,gBAAA;AAHV;AAUU;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;AARZ","sourcesContent":[".ant-upload-wrapper.common-avatar-uploader {\n  .ant-upload {\n    &.ant-upload-select {\n      width: 14rem;\n      height: 14rem;\n      border: 0.1rem solid #dddce2;\n      background-color: #e9f0f3;\n\n      &:hover {\n        border: 0.1rem solid #dddce2;\n      }\n\n      > .ant-upload {\n        .common-uploader-button {\n          cursor: pointer;\n          border: 0;\n          background: none;\n\n          .anticon {\n            > svg {\n            }\n          }\n\n          .upload-button-label {\n            font-size: 1.4rem;\n            font-weight: 600;\n            color: #2f2c39;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
