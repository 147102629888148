// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Plus Jakarta Sans", serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.left-container {
  background: linear-gradient(136.2deg, #eff2f4 50.59%, #88b3c1 113.23%);
  height: 100vh;
  padding: 40px;
  overflow: auto;
}
.left-content {
  max-width: 600px;
  width: 100%;
}
.right-container {
  background-color: white;
  height: 100vh;
  padding: 40px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-content {
  flex: 1 1;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AACA;EACE,kDAAkD;EAClD,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,sEAAsE;EACtE,aAAa;EACb,aAAa;EACb,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,WAAW;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap\");\n* {\n  font-family: \"Plus Jakarta Sans\", serif !important;\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.left-container {\n  background: linear-gradient(136.2deg, #eff2f4 50.59%, #88b3c1 113.23%);\n  height: 100vh;\n  padding: 40px;\n  overflow: auto;\n}\n.left-content {\n  max-width: 600px;\n  width: 100%;\n}\n.right-container {\n  background-color: white;\n  height: 100vh;\n  padding: 40px;\n  overflow: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.right-content {\n  flex: 1;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
