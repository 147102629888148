// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor-card-wrapper {
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: 0.1rem solid #dddce2;
}
.mentor-card-wrapper .mentor-icon-container {
  height: 27.2rem;
  width: 18.4rem;
}
.mentor-card-wrapper .mentor-icon-container .mentor-icon {
  width: 100%;
  height: 100%;
}
.mentor-card-wrapper .mentor-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mentor-card-wrapper .mentor-details-container .mentor-card-tags-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mentor-card-wrapper .mentor-details-container .mentor-detail-button {
  margin-top: 1rem;
  max-width: 22rem;
}
.mentor-card-wrapper .mentor-details-container .mentor-detail-button .mentor-details-button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/employerTalent/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,eAAA;EAEA,qBAAA;EACA,4BAAA;AAAJ;AAEI;EACE,eAAA;EACA,cAAA;AAAN;AAEM;EACE,WAAA;EACA,YAAA;AAAR;AAII;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFN;AAOM;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AALR;AAQM;EACE,gBAAA;EACA,gBAAA;AANR;AAQQ;EACE,WAAA;AANV","sourcesContent":[".mentor-card-wrapper {\n    display: flex;\n    gap: 1.6rem;\n    padding: 1.6rem;\n  \n    border-radius: 1.6rem;\n    border: 0.1rem solid #dddce2;\n  \n    .mentor-icon-container {\n      height: 27.2rem;\n      width: 18.4rem;\n  \n      .mentor-icon {\n        width: 100%;\n        height: 100%;\n      }\n    }\n  \n    .mentor-details-container {\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n  \n      .mentor-name {\n      }\n  \n      .mentor-card-tags-container {\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n      }\n  \n      .mentor-detail-button {\n        margin-top: 1rem;\n        max-width: 22rem;\n  \n        .mentor-details-button {\n          width: 100%;\n        }\n      }\n    }\n  }\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
